import {
    Alert, Autocomplete, Backdrop, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Rating, Skeleton, Slide,
    Snackbar, TextField, Typography, LinearProgress, Tabs, Tab, Avatar, Stack
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import React from "react";
import DBHelper from "src/api/service";
import Validator from "./Validator";
import CloseIcon from '@mui/icons-material/Close';
import Constants from "./Constants";
import StarBorderIcon from '@mui/icons-material/StarBorder';
import SendOutlined from '@mui/icons-material/SendOutlined';
import CancelOutlined from '@mui/icons-material/CancelOutlined';
import ReplyIcon from '@mui/icons-material/Reply';
// import GoogleAPI from "src/api/google.api";

export interface IAutoCompleteOption {
    key: string;
    value: string;
    text: any;
}

export interface IAutoCompleteProps {
    id: string;
    data: IAutoCompleteOption[];
    className?: string;
    defaultValue?: string;
    readOnly?: boolean;
    placeholder?: string;
    onChange(selectedOption: IAutoCompleteOption);
}

export class AutoComplete extends React.Component<IAutoCompleteProps, {}> {
    public state: any = {};
    private containerRef;
    private optionsStyle: React.CSSProperties = {
        zIndex: 2,
        backgroundColor: '#f9f9f9',
        minWidth: '90%',
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: '#ececec',
        listStyleType: 'none',
        margin: '0px',
        padding: '0px',
        maxHeight: '250px',
        overflow: 'auto',
        position: 'absolute'
    };

    private liAnchor: React.CSSProperties = {
        fontSize: '12px',
        display: 'block',
        paddingTop: '0.4rem',
        paddingRight: '10px',
        color: '#283249',
        textDecoration: 'none',
        fontWeight: 400,
        paddingLeft: '5px'
    };

    private lastLi: React.CSSProperties = {
        borderBottomWidth: '1px',
        borderBottomStyle: 'solid',
        borderBottomColor: '#ececec',

    };

    private liHover: React.CSSProperties = {
        backgroundColor: '#eee',
        color: '#00589c',
        cursor: 'pointer'
    };

    constructor(props) {
        super(props);
        this.state = { activeOption: 0, filteredOptions: [], showOptions: false, userInput: "", text: "", selectedOption: null, isSelected: false };
        this.onBlur = this.onBlur.bind(this);
        this.containerRef = React.createRef();
    }

    public componentDidMount() {
        document.addEventListener('mousedown', this.onBlur);
    }

    public componentWillUnmount() {
        document.removeEventListener('mousedown', this.onBlur);
    }

    private onChange(e) {
        const { data } = this.props;
        var userInput = e.currentTarget.value;
        userInput = userInput.trim();

        let filteredOptions: IAutoCompleteOption[] = [];

        const tempFilters = data.filter((option) => { return (option.key.toLowerCase().indexOf(userInput.toLowerCase()) > -1); });
        if (tempFilters && tempFilters.length > 0) {
            filteredOptions.push(...tempFilters);
        }

        this.setState({ activeOption: 0, filteredOptions, showOptions: true, userInput, isSelected: false, selectedOption: null });
    }

    public onClick(e, selectedOption) {
        this.setState({ activeOption: 0, filteredOptions: [], showOptions: false, selectedOption: selectedOption, userInput: selectedOption.key, text: selectedOption.text, isSelected: true });
        this.props.onChange(selectedOption);
    }

    public onKeyDown(e) {
        const { activeOption, filteredOptions } = this.state;

        if (e.keyCode === 13) { // Enter key is pressed
            this.setState({ activeOption: 0, showOptions: false, userInput: filteredOptions[activeOption].key, selectedoption: filteredOptions[activeOption] });
            this.props.onChange(filteredOptions[activeOption]);
        }
        else if (e.keyCode === 38) { // Press Up arrow key
            if (activeOption === 0) {
                return;
            }

            this.setState({ activeOption: activeOption - 1 });
        }
        else if (e.keyCode === 40) { // Press Down arrow key
            if (activeOption - 1 === filteredOptions.length) {
                return;
            }

            this.setState({ activeOption: activeOption + 1 });
        }
    }

    private onBlur(e) {
        if (this.containerRef && this.containerRef.current && !this.containerRef.current.contains(e.target)) {
            if (e.target && e.target.id === this.props.id) {
                if (this.state.isSelected)
                    this.setState({ activeOption: 0, showOptions: false, isSelected: false, selectedOption: null });
                else {
                    this.setState({ activeOption: 0, showOptions: false });
                    this.props.onChange(this.state.selectedOption);
                }
            }
            else {
                this.setState({ activeOption: 0, showOptions: false });
            }
        }
    }

    public render() {
        const { activeOption, filteredOptions, showOptions } = this.state;

        let optionsListComponent;
        let nodeStyle: React.CSSProperties = {};

        if (showOptions) {
            if (filteredOptions.length) {
                optionsListComponent = (
                    <ul style={this.optionsStyle}>
                        {filteredOptions.map((option, index) => {
                            if (index === activeOption) {
                                nodeStyle = Object.assign({}, this.liHover);
                            }
                            else
                                nodeStyle = {};

                            if (index === filteredOptions.length - 1)
                                nodeStyle = Object.assign({}, nodeStyle, this.lastLi);

                            return (
                                <li style={nodeStyle} key={this.props.id + '_' + option.value} onMouseEnter={(e) => { this.setState({ activeOption: index }); }} onMouseLeave={(e) => { this.setState({ activeOption: 0 }); }}>
                                    <a href='javascript:void(0);' style={this.liAnchor} onClick={(e) => { this.onClick(e, option); }}>{option.text}</a>
                                </li>
                            );
                        })}
                    </ul>
                );
            }
        }

        return (<div id={this.props.id + '_dvRef'} key={this.props.id + '_dv'} ref={this.containerRef} style={{ width: '100%', height: '100%' }}>
            <input type="text" id={this.props.id
            } key={(this.state.showOptions ? this.props.id + '_acB' : this.props.id + '_ac') + (this.props.defaultValue ? this.props.defaultValue.length : 0)}
                className={this.props.className} readOnly={this.props.readOnly ? this.props.readOnly : false} defaultValue={this.props.defaultValue}
                title={this.props.defaultValue} onClick={(e) => { this.onChange(e); }} onChange={(e) => { this.onChange(e); }} onKeyDown={(e) => { this.onKeyDown(e); }}
                placeholder={this.props.placeholder} />
            {optionsListComponent}
            { }
        </div >
        );
    }
}

export interface IRatingsProps {
    id: string;
    itemId: number;
    rating: number;
    allowRating: boolean;
    maxRatingAllowed: number;
    onRatingChanged?: any;
}

export class Ratings extends React.Component<IRatingsProps, any> {

    constructor(props) {
        super(props);

        this.state = { loading: true };
    }

    public componentDidMount() {
        this.setState({ loading: false });
    }

    public render() {
        const { loading } = this.state;

        let template: any = null;
        if (!loading) {
            let _stars: JSX.Element[] = [];
            for (var i = 0; i < this.props.maxRatingAllowed; i++) {
                if (this.props.rating > 0) {
                    if (i <= this.props.rating - 1)
                        _stars.push(this.props.allowRating ? (<a id={'ancRate_' + this.props.id + '_' + this.props.itemId} href='#'><i id={'iRate_' + this.props.id + '_' + this.props.itemId} className="fa fa-star fa-star-full" aria-hidden="true" onClick={(e) => { this.props.onRatingChanged(this.props.itemId, i + 1); }}></i></a>) : (<i id={'iRate_' + this.props.id + '_' + this.props.itemId} className="fa fa-star" aria-hidden="true"></i>));
                    else
                        _stars.push(this.props.allowRating ? (<a id={'ancRate_' + this.props.id + '_' + this.props.itemId} href='#'><i id={'iRate_' + this.props.id + '_' + this.props.itemId} className="fa fa-star" aria-hidden="true" onClick={(e) => { this.props.onRatingChanged(this.props.itemId, i + 1); }}></i></a>) : (<i id={'iRate' + this.props.id + '_' + this.props.itemId} className="fa fa-star" aria-hidden="true"></i>));
                }
                else
                    _stars.push(this.props.allowRating ? (<a id={'ancRate_' + this.props.id + '_' + this.props.itemId} href='#'><i id={'iRate_' + this.props.id + '_' + this.props.itemId} className="fa fa-star" aria-hidden="true" onClick={(e) => { this.props.onRatingChanged(this.props.itemId, i + 1); }}></i></a>) : (<i id={'iRate_' + this.props.id + '_' + this.props.itemId} className="fa fa-star" aria-hidden="true"></i>));
            }
        }

        return template;
    }
}

export interface ICommentProps {
    id: string;
    itemId: number;
    comments: IComment[];
    allowComments: boolean;
    allowReply: boolean;
    onCommentUpdated?: any;
}

export interface IComment {
    id?: number;
    text: string;
    author: string;
    createdOn: string;
    parentId?: number;
}

export class Comments extends React.Component<ICommentProps, any> {

    constructor(props) {
        super(props);

        this.state = { loading: true };
    }

    public componentDidMount() {
        this.setState({ loading: false });
    }

    public render() {
        const { loading } = this.state;

        let template: any = null;
        if (!loading) {
            template = (<React.Fragment>
                <span className="comments">({this.props.comments ? this.props.comments.length : 0})</span>
            </React.Fragment>);
        }

        return template;
    }
}

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
    id: string;
}

export const TabPanel = (props: TabPanelProps) => {
    const { id, children, value, index, ...other } = props;

    return (
        <div role="tabpanel" hidden={value !== index} id={id} aria-labelledby={id} {...other} >
            {value === index && (<Box sx={{ p: 3 }}>
                {children}
            </Box>)}
        </div>
    );
}

export const Loader = (props: any) => {
    return (
        <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={props.open} onClick={props.onClick} >
            <CircularProgress color="inherit" />
        </Backdrop>
    );
}

export const ProgressBar = (props: any) => {
    return (<React.Fragment>
        <Skeleton animation="wave" />
        <Skeleton animation="wave" />
        <Skeleton animation="wave" />
    </React.Fragment>
    );
}

interface ProfilePicProps {
    id?: string;
    src?: string;
    alt: string;
    className?: string;
}

export const ProfilePic = (props: ProfilePicProps) => {
    return (<div className={"align-self-center " + props.className ? props.className : ''} style={{ backgroundImage: `url(${props.src ? props.src : '/images/profile.png'})` }}></div>);
}

interface ReviewProps {
    id?: string;
    showRating?: boolean;
    allowRating?: boolean;
    defaultRating?: number;
    ratingClassName?: string;
    showReviewCount?: boolean;
    defaultReviews?: number;
    allowAskForReviews?: boolean;
    profileId: number;
    reviewerUser: any;
}

export default class Reviews extends React.Component<ReviewProps, any> {

    public state: any;
    private validator: Validator;
    private formValidators: Validator[];
    private commentReplyFormValidators: Validator[];

    private SlideDownTransition = React.forwardRef(function Transition(props: TransitionProps & { children: React.ReactElement<any, any>; }, ref: React.Ref<unknown>,) { return <Slide direction="down" ref={ref} {...props} />; });
    private SlideUpTransition = React.forwardRef(function Transition(props: TransitionProps & { children: React.ReactElement<any, any>; }, ref: React.Ref<unknown>,) { return <Slide direction="up" ref={ref} {...props} />; });
    private AlertTransition = React.forwardRef(function Transition(props: TransitionProps & { children: React.ReactElement<any, any>; }, ref: React.Ref<unknown>,) { return <Slide direction="left" ref={ref} {...props} />; });

    private db = new DBHelper();

    constructor(props) {
        super(props);
        this.state = {
            loading: true, showReviewsDialog: false, showAskForReviewDialog: false, showReplyId: '0', ReplyText: '', Emails: '', showConfirmation: false, ConfirmationType: '', ConfirmationMessage: '', ShowMessage: false, MessageType: 'success', Profile: null,
            Message: '', ItemId: 0, callBackMethod: null, NewRating: this.props.defaultRating, ProfileId: this.props.profileId, Comment: '', ReviewParameters: [], Recipients: null, ReviewRecipients: [],
            ProfileRatings: [], ProfileComments: [], HighRatedParameters: [], ProfileRootComments: [], ParentCommentId: '0', showReviewsProgress: false, ActiveReviewTab: 0,
            BusinessLocation: null, GoogleRating: 0, UserRole: ''
        };

        this.validator = new Validator({});
        this.formValidators = new Array<Validator>();
        this.commentReplyFormValidators = new Array<Validator>();
    }

    public componentDidMount(): void {
        //let db = new DBHelper();
        this.addGoogleMapScriptTag();

        this.db.getRecipients(this.props.profileId, 'P').then((recipients) => {
            this.db.getUserProfileById(this.props.profileId, Constants.UserRoles.Pro).then((profile) => {
                this.db.getReviewParameters().then((pars) => {
                    this.db.getProfileRatings(this.props.profileId).then((profileRatings) => {
                        this.db.getProfileComments(this.props.profileId).then((profileComments) => {
                            let rootComments = [];
                            if (profileComments) {
                                rootComments = profileComments.filter((c) => { return (c.ParentId.toString() === '0'); });
                            }

                            let bLocation: any = profile.GoogleBusinessLocation;
                            if (bLocation)
                                bLocation = JSON.parse(bLocation);

                            if (profile?.GoogleBusinessLocation) {
                                let gLocation = JSON.parse(profile.GoogleBusinessLocation);
                                if (gLocation) {
                                    let gPlaceId = gLocation.placeId;
                                    if (gPlaceId) {
                                        this.getGoogleReviews(gPlaceId);
                                    }
                                }
                            }

                            this.setState({
                                loading: false, ReviewParameters: pars, ReviewRecipients: recipients, Profile: profile, ProfileRatings: profileRatings, UserRole: profile.User?.DefaultRole,
                                ProfileComments: profileComments, ProfileRootComments: rootComments, HighRatedParameters: profile.HighRatedParameters, BusinessLocation: bLocation
                            });
                        });
                    });
                });
            });
        });
    }

    private addGoogleMapScriptTag() {
        let scriptElems = document.getElementsByTagName("script");
        let elemExists = false;
        for (var i = 0; i < scriptElems.length; i++) {
            if (!elemExists && scriptElems[i].src === Constants.Google.PlacesScriptPath)
                elemExists = true;
        }

        if (!elemExists) {
            const script = document.createElement("script");
            script.src = Constants.Google.PlacesScriptPath;
            //script.id = "scriptGoogleMaps";
            script.setAttribute('async', '');
            script.setAttribute('defer', '');

            document.getElementsByTagName("head")[0].appendChild(script);
        }
    }

    private handleAskForReviewsClick() {
        let isValid = this.validator.validateForm(this.formValidators);
        if (isValid && this.state.Recipients && this.state.Recipients.length > 0 && this.state.Profile) {
            let db = new DBHelper();

            let emailTemplate = Constants.Email.AskForReviews;
            let subject = emailTemplate.Subject.replace('{0}', this.state.Profile.BusinessName);
            let body = emailTemplate.Body;

            let counter = 0;

            this.state.Recipients.forEach((cust, indx) => {
                body = body.replace("[username]", cust.Profile.User.FirstName);
                body = body.replace("[businessname]", this.state.Profile.BusinessName);
                body = body.replace("[rid]", this.state.Profile.Id.toString());
                body = body.replace("[em]", this.state.Profile.User.Email);

                if (cust.Profile.User.Email) {
                    db.sendEmail(cust.Profile.User.Email, subject, body).then((emailSent) => {
                        counter++;
                        if (counter === this.state.Recipients.length) {
                            this.successMessage('Review request sent successfully.');
                            this.formValidators = new Array<Validator>();
                            this.setState({ showAskForReviewDialog: false, Recipients: [] });
                        }
                    });
                }
            });

            // let _emails: string = '';

            // this.state.Recipients.forEach((cust, indx) => {
            //     if (indx < this.state.Recipients.length - 1)
            //         _emails += cust.Profile.User.Email + ",";
            //     else
            //         _emails += cust.Profile.User.Email;
            // });           

            // db.askForReviews(_emails, this.state.ProfileId).then((asked) => {
            //     if (asked) {
            //         this.successMessage('Review request sent successfully.');
            //         this.formValidators = new Array<Validator>();
            //         this.setState({ showAskForReviewDialog: false, Recipients: [], ReviewRecipients: [] });
            //     }
            //     else {
            //         this.errorMessage('Review request could not be sent.');
            //     }
            // });
        }
    }

    private handleReviewSubmit() {
        let db = new DBHelper();

        let reviewParameters = '';
        if (this.state.ReviewParameters && this.state.ReviewParameters.length > 0) {
            reviewParameters = JSON.stringify(this.state.ReviewParameters);
        }

        db.addProfileRating(this.props.profileId, this.state.NewRating, this.props.reviewerUser.DisplayName, this.props.reviewerUser.Email, reviewParameters).then((rated) => {
            if (rated) {
                if (this.state.Comment) {
                    db.addProfileComment(this.props.profileId, this.state.Comment, '0', this.props.reviewerUser.DisplayName, this.props.reviewerUser.Email, this.state.NewRating).then((commented) => {
                        if (commented) {
                            this.successMessage('Review submitted successfully.');
                            this.setState({ showAddReviewDialog: false, NewRating: '', Comment: '' });
                        }
                        else {
                            this.errorMessage('Review comment could not be submitted.');
                        }
                    });
                }
                else {
                    this.successMessage('Review submitted successfully.');
                    this.setState({ showAddReviewDialog: false, NewRating: '', Comment: '' });
                }
            }
            else {
                this.errorMessage('Review rating could not be submitted.');
            }
        });
    }

    private getHighRatedFor() {
        let highRated = this.state.HighRatedParameters;
        let template = null;

        if (highRated && highRated.length > 0) {
            template = highRated.map((hR, indx) => {
                if (indx < highRated.length - 1)
                    return (<span className="highRatedParameter">{hR.Name}, </span>);
                else
                    return (<span className="highRatedParameter"> and {hR.Name}.</span>);
            });
        }

        return template;
    }

    private getReviews() {
        this.setState({ showReviewsProgress: true });
        this.db.getRecipients(this.props.profileId, 'P').then((recipients) => {
            this.db.getProfileRatings(this.props.profileId).then((profileRatings) => {
                this.db.getProfileComments(this.props.profileId).then((profileComments) => {
                    let rootComments = [];
                    if (profileComments) {
                        rootComments = profileComments.filter((c) => { return (c.ParentId.toString() === '0'); });
                    }

                    this.setState({ showReviewsProgress: false, ProfileRatings: profileRatings, ProfileComments: profileComments, ProfileRootComments: rootComments, ReviewRecipients: recipients });
                });
            });
        });
    }

    private postComment(parentComment) {
        const { ProfileId, ReplyText } = this.state;

        let isValid = this.validator.validateForm(this.commentReplyFormValidators);
        if (isValid) {
            this.setState({ showReviewsProgress: true });
            this.db.addProfileComment(ProfileId, ReplyText, parentComment.Id, this.props.reviewerUser.DisplayName, this.props.reviewerUser.Email, 0).then((added) => {
                if (added) {
                    this.successMessage('Comment posted successfully.');
                    this.setState({ showReplyId: '0', ParentCommentId: '0', ReplyText: '' });
                    this.getReviews();
                }
                else {
                    this.errorMessage('Comment could not be posted.');
                    this.setState({ showReviewsProgress: false });
                }
            });
        }
    }

    private getChildComments(comment) {
        const { showReplyId, ProfileComments, ReplyText } = this.state;
        let childComments = ProfileComments.filter((pC) => { return (pC.ParentId === comment.Id); });

        if (childComments && childComments.length > 0) {
            let template = null;
            template = childComments.map((cmt) => {
                return (<div className="row">
                    <div className="col-12 col-sm-12">
                        <div className="commentReplyContainer">
                            <div className="row">
                                <div className="col-12 col-sm-3 pt-1">
                                    <Stack direction="row"><Avatar alt={cmt.UserName} src={cmt.ProfileImage ? cmt.ProfileImage : '/images/profile.png'} variant="circular" sx={{ width: 30, height: 30 }}></Avatar><span className="commentAuthor ml-2">{cmt.UserName}'s reply</span></Stack>
                                </div>
                                <div className="col-12 col-sm-2 pt-1">
                                    <span className="commentDate">{this.getFormattedDate(new Date(this.getDateNumber(cmt.CreatedOn)), Constants.IDateTimeFormat.ddMMMyyyy, '/')}</span>
                                </div>
                                <div className="col-12 col-sm-7 text-right pr-0">
                                    {showReplyId !== cmt.Id.toString() && this.props.reviewerUser?.Profile?.Id === cmt.ProfileId ? (<div><Typography variant="caption"><ReplyIcon /><a href="javascript:void(0);" onClick={(e) => { this.setState({ showReplyId: cmt.Id.toString() }); }} title="Reply" className="ml-2">Reply</a></Typography></div>) : (null)} {/*&& this.props.profileId === cmt.ProfileId*/}
                                </div>
                                <div className="col-12 col-sm-12">
                                    <span className="commentBody">{cmt.CommentText}</span>
                                </div>
                                {showReplyId === cmt.Id.toString() ? (
                                    <div className="row">
                                        <div className="col-12 col-sm-12">
                                            <div className="commentReplyContainer">
                                                <div className="row">
                                                    <div className="col-12 col-sm-9">
                                                        <input key={"txtReply_" + cmt.Id.toString()} id={"txtReply_" + cmt.Id.toString()} name={"txtReply_" + cmt.Id.toString()} className="form-control" defaultValue={ReplyText} autoFocus={true} onBlur={(e) => { this.setState({ ReplyText: e.target.value }); }} />
                                                        <Validator key={"valReplyText_" + cmt.Id.toString()} id={"valReplyText_" + cmt.Id.toString()} type="required" errorMessage="Provide reply text." controlId={"txtReply_" + cmt.Id.toString()} cssClass="error" valueToValidate={ReplyText} formValidators={this.commentReplyFormValidators}></Validator>
                                                    </div>
                                                    <div className="col-12 col-sm-3 text-right">
                                                        <IconButton key={"iconPostBtn_" + cmt.Id.toString()} onClick={(e) => { this.postComment(cmt); }} title="Post">
                                                            <SendOutlined />
                                                        </IconButton>
                                                        <IconButton key={"iconCancelBtn_" + cmt.Id.toString()} onClick={(e) => { this.setState({ showReplyId: '0', ParentCommentId: '0', ReplyText: '' }); }} title="Cancel">
                                                            <CancelOutlined />
                                                        </IconButton>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>) : (null)}
                                <React.Fragment>
                                    {this.getChildComments(cmt)}
                                </React.Fragment>
                            </div>
                        </div>
                    </div>
                </div>);
            });

            return template;
        }
        else {
            return (null);
        }
    }

    public getDateNumber(val) {
        let rawVal = val.substring(val.indexOf('(') + 1, val.indexOf(')'));
        let _val = [];

        if (rawVal.indexOf('-') > 0) {
            _val = rawVal.split('-');
            rawVal = Number(_val[0]) - Number(_val[1]);
        }
        else if (rawVal.indexOf('+') > 0) {
            _val = rawVal.split('+');
            rawVal = Number(_val[0]) + Number(_val[1]);
        }

        return rawVal;
    }

    public getFormattedDate(value: any, format: Constants.IDateTimeFormat, separator: string): string {
        let retVal: any = value;
        let months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

        if (value) {
            let objDate = new Date(value);
            if (objDate) {
                let datePart: any = objDate.getDate();
                datePart = (datePart < 10 ? '0' + datePart.toString() : datePart.toString());

                let monthPart: any = objDate.getMonth() + 1;
                monthPart = (monthPart < 10 ? '0' + monthPart.toString() : monthPart.toString());
                if (format === Constants.IDateTimeFormat.MMMddyyyy || format === Constants.IDateTimeFormat.ddMMMyyyy || format === Constants.IDateTimeFormat.MMMyyyy || format === Constants.IDateTimeFormat.ddMMM) {
                    monthPart = months[objDate.getMonth()];
                }

                let yearPart: any = objDate.getFullYear();
                if (format === Constants.IDateTimeFormat.MMddyy || format === Constants.IDateTimeFormat.ddMMyy || format === Constants.IDateTimeFormat.yyMMdd) {
                    yearPart = yearPart.toString().substring(2);
                }

                let hours: any = objDate.getHours();
                let minutes: any = objDate.getHours();
                //let seconds: any = objDate.getHours();
                let amPM = (hours === 0 ? ('AM') : (hours >= 12 ? 'PM' : 'AM'));
                hours = (hours > 12 ? hours - 12 : hours);
                hours = (hours < 10 ? "0" + hours : hours);

                if (format === Constants.IDateTimeFormat.MMddyyyy) {
                    retVal = monthPart + separator + datePart + separator + yearPart;
                }
                else if (format === Constants.IDateTimeFormat.ddMMyyyy) {
                    retVal = datePart + separator + monthPart + separator + yearPart;
                }
                else if (format === Constants.IDateTimeFormat.yyyyMMdd) {
                    retVal = yearPart + separator + monthPart + separator + datePart;
                }
                else if (format === Constants.IDateTimeFormat.MMMddyyyy) {
                    retVal = monthPart + separator + datePart + separator + yearPart;
                }
                else if (format === Constants.IDateTimeFormat.MMddyy) {
                    retVal = monthPart + separator + datePart + separator + yearPart;
                }
                else if (format === Constants.IDateTimeFormat.ddMMMyyyy) {
                    retVal = datePart + separator + monthPart + separator + yearPart;
                }
                else if (format === Constants.IDateTimeFormat.ddMMyy) {
                    retVal = datePart + separator + monthPart + separator + yearPart;
                }
                else if (format === Constants.IDateTimeFormat.yyMMdd) {
                    retVal = yearPart + separator + monthPart + separator + datePart;
                }
                else if (format === Constants.IDateTimeFormat.ddMM) {
                    retVal = datePart + separator + monthPart;
                }
                else if (format === Constants.IDateTimeFormat.ddMMM) {
                    retVal = datePart + separator + monthPart;
                }
                else if (format === Constants.IDateTimeFormat.MMyyyy) {
                    retVal = monthPart + separator + yearPart;
                }
                else if (format === Constants.IDateTimeFormat.MMMyyyy) {
                    retVal = monthPart + separator + yearPart;
                }
                else if (format === Constants.IDateTimeFormat.hhmm) {
                    retVal = hours + separator + minutes + ' ' + amPM;
                }
            }
        }
        return retVal;
    }

    private handleReviewTabClick(index) {
        this.setState({ showReviewsProgress: true, ActiveReviewTab: index }, () => {
            if (index === 1) {
                if (this.state.Profile && this.state.Profile.GoogleBusinessLocation) {
                    let bLocation = JSON.parse(this.state.Profile.GoogleBusinessLocation);
                    if (bLocation) {
                        let placeId = bLocation.placeId;
                        if (placeId) {
                            this.getGoogleReviews(placeId);
                        }
                        else {
                            this.setState({ showReviewsProgress: false });
                        }
                    }
                    else {
                        this.setState({ showReviewsProgress: false });
                    }
                }
                else {
                    this.setState({ showReviewsProgress: false });
                }
            }
            else {
                this.setState({ showReviewsProgress: false });
            }
        });
    }

    // private getGoogleReviews(locationId) {
    //     if (!this.state.GoogleReviews) {
    //         let gAPI = new GoogleAPI();
    //         gAPI.getPlaceReviews(locationId).then((place) => {
    //             let reviews: any = [];

    //             if (place) {
    //                 reviews = place.reviews;
    //                 if (reviews && reviews.length > 0) {
    //                     reviews.sort((a, b) => {
    //                         return a.reviewRating < b.reviewRating ? 1 : -1;
    //                     });
    //                 }
    //             }

    //             this.setState({ showReviewsProgress: false, GoogleReviews: reviews });
    //         });
    //     }
    //     else {
    //         this.setState({ showReviewsProgress: false });
    //     }
    // }

    private getGoogleReviews(locationId): Promise<any> {
        return new Promise((resolve, reject) => {
            const div = document.getElementById("map") as HTMLDivElement;

            let placeService = new google.maps.places.PlacesService(div);
            placeService.getDetails({ placeId: locationId, fields: ["formatted_address", "name", "place_id", "reviews", "rating"] }, (place, status) => {
                if (status === google.maps.places.PlacesServiceStatus.OK) {
                    let reviews: any = [];
                    let rating: any = 0;

                    if (place) {
                        reviews = place.reviews;
                        rating = place.rating;
                    }

                    this.setState({ showReviewsProgress: false, GoogleReviews: reviews, GoogleRating: rating });
                    resolve({ GoogleReviews: reviews, GoogleRating: rating });
                }
                else if (status === google.maps.places.PlacesServiceStatus.OVER_QUERY_LIMIT) {
                    this.errorMessage('OVER_QUERY_LIMIT exception is thrown by Google API.');
                    this.setState({ showReviewsProgress: false });
                    resolve(null);
                }
                else {
                    this.errorMessage(status + ' exception is thrown by Google API.');
                    this.setState({ showReviewsProgress: false });
                    resolve(null);
                }
            });
        });
    }

    private initializePlacesAutoComplete() {
        this.setState({ showGoogleReview: true }, () => {
            const input = document.getElementById("business") as HTMLInputElement;
            const autoCompleteOptions = {
                fields: ["formatted_address", "address_components", "geometry", "name", "place_id", "reviews"],
                // strictBounds: false,
                types: ["address"],
                componentRestrictions: { country: "us" }
            };

            const autocomplete = new google.maps.places.Autocomplete(input, autoCompleteOptions);

            autocomplete.addListener("place_changed", () => {
                let place: any = autocomplete.getPlace();

                if (!place.geometry || !place.geometry.location) {
                    this.setState({ showConfirmation: true, ConfirmationMessage: "No details available for input: '" + place.name + "'", ConfirmationType: 'A' });
                    return;
                }

                let lat = place.geometry?.location?.lat();
                let lon = place.geometry?.location?.lng();
                let formatted_address = place?.formatted_address;
                let placeId = place?.place_id;

                this.setState({ BusinessLocation: { name: place?.name, lat: lat, lng: lon, formatted_address: formatted_address, placeId: placeId } });
            });
        });
    }

    private saveGoogleBusinessAccount() {
        let isValid = this.validator.validateForm(this.formValidators);
        if (isValid) {
            let businessLocation = JSON.stringify(this.state.BusinessLocation);
            this.db.updateProfileGoogleBusinessAccount(this.state.Profile.Id.toString(), businessLocation).then((updated) => {
                if (updated) {
                    this.formValidators = new Array<Validator>();
                    this.setState({ showGoogleReview: false, BusinessLocation: null });
                    this.successMessage('Google Business Account details saved.');
                }
                else {
                    this.errorMessage('Google Business Account details could not be saved.');
                }
            });
        }
    }

    private errorMessage(message: string) {
        this.setState({ Message: message, MessageType: 'error', ShowMessage: true });
    }

    private successMessage(message: string) {
        this.setState({ Message: message, MessageType: 'success', ShowMessage: true });
    }

    private getReviewsFigure(count: any) {
        if (count) {
            let _count = Number(count);
            if (_count > 0) {
                if (_count < 1000)
                    return count.toString();
                else {
                    return (_count / 1000).toString();
                }
            }
        }

        return "0";
    }

    public render() {

        const { loading, showAskForReviewDialog, Recipients, ReviewRecipients, showConfirmation, ConfirmationType, ConfirmationMessage, ShowMessage, MessageType, Message,
            showAddReviewDialog, NewRating, Comment, ReviewParameters, showReviewsDialog, showReplyId, ReplyText, HighRatedParameters, ProfileRootComments, ProfileRatings,
            ActiveReviewTab, BusinessLocation, showGoogleReview, GoogleReviews, GoogleRating, showReviewsProgress } = this.state;

        let template = <ProgressBar />;

        if (!loading) {
            template = (<React.Fragment>
                <div>
                    {this.props.showRating ? (
                        <><Typography variant="caption" className="pr-2"><img style={{ verticalAlign: 'top' }} src='/images/prosBEIcon.png' alt='ProsBE reviews' /></Typography><Rating value={this.props.defaultRating} className={this.props.ratingClassName} readOnly={!this.props.allowRating} onChange={(e, newVal) => { if (this.props.allowRating) { this.setState({ showAddReviewDialog: true, NewRating: newVal }); } }} /></>) : (null)}
                    {this.props.showReviewCount ? (
                        <Typography variant='caption' className="commentBody pl-2">{this.props.defaultReviews === undefined || this.props.defaultReviews === 0 ? "0 reviews" : (<a href="javascript:void(0);" onClick={(e) => { this.setState({ showReviewsDialog: true, Emails: '', ActiveReviewTab: 0 }); }}>{this.getReviewsFigure(this.props.defaultReviews)} {this.props.defaultReviews === 1 ? " review" : " reviews"}</a>)}</Typography>) : (null)}
                    {this.props.allowAskForReviews ? (
                        <Typography variant="caption" className="pl-2"><a href="javascript:void(0);" onClick={(e) => { this.setState({ showAskForReviewDialog: true, Emails: '', ActiveReviewTab: 0 }); }}>Ask for Reviews</a></Typography>
                    ) : (null)}
                </div>
                <div className="mt-2">
                    <Typography variant="caption" className="pr-2"><img style={{ verticalAlign: 'top' }} src='/images/googleIcon.png' alt='Google reviews' /></Typography><Rating value={GoogleRating} className={this.props.ratingClassName} readOnly={true} />
                    <Typography variant='caption' className="commentBody pl-2">{!GoogleReviews || GoogleReviews.length <= 0 ? "0 reviews" : (<a href="javascript:void(0);" onClick={(e) => { this.setState({ showReviewsDialog: true, Emails: '', ActiveReviewTab: 1 }); }}>{this.getReviewsFigure(GoogleReviews?.length)} {GoogleReviews?.length === 1 ? " review" : " reviews"}</a>)}</Typography>
                </div>
                {!showAskForReviewDialog ? (null) : (<React.Fragment>
                    <Dialog fullWidth={true} maxWidth={"sm"} open={showAskForReviewDialog} TransitionComponent={this.SlideDownTransition} keepMounted onClose={(e) => { this.formValidators = new Array<Validator>(); }} aria-describedby="askforReviewsDialog" >
                        <DialogTitle id={"dialogAskForReviews"} className="modalTitle">{'Ask for Reviews'}
                            <IconButton aria-label="close" onClick={(e) => { this.formValidators = new Array<Validator>(); this.setState({ showAskForReviewDialog: false, Recipients: [] }); }} className="btnCloseDialog">
                                <CloseIcon />
                            </IconButton>
                        </DialogTitle>
                        <DialogContent dividers={true}>
                            <div className="profileContainer">
                                <div className="formContainer">
                                    <div className="col-12 col-sm-12">
                                        <div className="form-group mt-3">
                                            <Button startIcon={<img src='/images/googleIcon.png' alt='Google reviews' />} disabled={showGoogleReview} onClick={(e) => { this.formValidators = new Array<Validator>(); this.initializePlacesAutoComplete(); }}> Add reviews from Google</Button>
                                        </div>
                                    </div>
                                    {showGoogleReview ? (<React.Fragment>
                                        <div className="col-12 col-sm-12">
                                            <div className="form-group mt-3">
                                                <label>Business Location (Address)</label>
                                                <input id="business" name="business" type="text" className="form-control" defaultValue={BusinessLocation ? BusinessLocation.formatted_address : ''} />
                                                <Validator id="valBusiness" type="required" cssClass="error" controlId="business" errorMessage="Provide business location." valueToValidate={BusinessLocation && BusinessLocation.placeId ? '1' : ''} formValidators={this.formValidators} />
                                                <div id="place"></div>
                                            </div>
                                        </div>
                                    </React.Fragment>) : (<React.Fragment>
                                        <div className="col-12 col-sm-12">
                                            <div className="form-group mt-3">
                                                <Typography variant="subtitle1" color="text.secondary" sx={{ fontWeight: 600 }}>Get more reviews</Typography>
                                                <Typography variant="subtitle2" color="text.secondary">Invite your customers to leave reviews</Typography>
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-12">
                                            <div className="form-group mt-3">
                                                <Autocomplete multiple={true} id="emails" options={ReviewRecipients && ReviewRecipients.length > 0 ? ReviewRecipients : []} getOptionLabel={(option) => { return (option && option.Profile.User.DisplayName ? option.Profile.User.DisplayName : ''); }}
                                                    onChange={(e, newVal) => { this.setState({ Recipients: newVal ? newVal : null }); }} defaultValue={Recipients} filterSelectedOptions={true} renderInput={(params) => (<TextField name="emails" {...params} label="" placeholder="" />)} />
                                                <Validator id="valEmails" type="required" cssClass="error" controlId="emails" errorMessage="Provide email." valueToValidate={Recipients && Recipients.length > 0 ? '1' : ''} formValidators={this.formValidators} />
                                            </div>
                                        </div>
                                    </React.Fragment>)}
                                </div>
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <div className="formButtonContainer text-right">
                                <button type="submit" className="btn btn-cancel mr-3" onClick={(e) => { this.formValidators = new Array<Validator>(); if (showGoogleReview) { this.setState({ showGoogleReview: false, BusinessLocation: null }); } else { this.setState({ showAskForReviewDialog: false, Recipients: [] }); } }}>Cancel</button>
                                <button type="submit" className="btn btn-primary cutmBtnLogin mr-3" onClick={(e) => { if (!showGoogleReview) { this.handleAskForReviewsClick(); } else { this.saveGoogleBusinessAccount(); } }}>{!showGoogleReview ? "Invite" : "Submit"}</button>
                            </div>
                        </DialogActions>
                    </Dialog>
                </React.Fragment>)}
                {!showAddReviewDialog ? (null) : (<React.Fragment>
                    <Dialog fullWidth={true} maxWidth={"sm"} open={showAddReviewDialog} TransitionComponent={this.SlideDownTransition} keepMounted onClose={(e) => { }} aria-describedby="submitReview" >
                        <DialogTitle id={"dialogSubmitReview"} className="modalTitle">{'Submit your Review'}
                            <IconButton aria-label="close" onClick={(e) => { this.setState({ showAddReviewDialog: false, Comment: '', NewRating: '' }); }} className="btnCloseDialog">
                                <CloseIcon />
                            </IconButton>
                        </DialogTitle>
                        <DialogContent dividers={true}>
                            <div className="profileContainer">
                                <div className="formContainer">
                                    <div className="form-group">
                                        <label>Overall rating</label>
                                        <div>
                                            <Rating key="newRating" name="newRating" value={NewRating} className="ratedStar" onChange={(e, newVal) => { this.setState({ NewRating: newVal }); }} />
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label>Service oriented rating</label>
                                        {!ReviewParameters || ReviewParameters.length <= 0 ? (null) : (<React.Fragment>
                                            {ReviewParameters.map((item, indx) => {
                                                return (
                                                    <div className="row">
                                                        <div className="col-4 col-sm-4">
                                                            <Rating key={"sRating_" + indx.toString()} name={"sRating_" + indx.toString()} value={item.Rating} className="ratedStar" onChange={(e, newVal) => { item.Rating = newVal; this.setState({ ReviewParameters: ReviewParameters }); }} />
                                                        </div>
                                                        <div className="col-8 col-sm-8">
                                                            <Typography variant="caption" color="text.secondary" className="commentBody">{item.Name}</Typography>
                                                        </div>
                                                    </div>);
                                            })}
                                        </React.Fragment>)}
                                    </div>
                                    <div className="form-group">
                                        <label>Comments</label>
                                        <textarea id="comment" name="comment" defaultValue={Comment} className="form-control" rows={4} onBlur={(e) => { this.setState({ Comment: e.target.value }); }} />
                                    </div>
                                </div>
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <div className="formButtonContainer text-right">
                                <button type="submit" className="btn btn-cancel mr-3" onClick={(e) => { this.setState({ showAddReviewDialog: false, Comment: '', NewRating: '' }); }}>Cancel</button>
                                <button type="submit" className="btn btn-primary cutmBtnLogin mr-3" onClick={(e) => { this.handleReviewSubmit(); }}>Save</button>
                            </div>
                        </DialogActions>
                    </Dialog>
                </React.Fragment>)}
                {!showReviewsDialog ? (null) : (<React.Fragment>
                    <Dialog fullWidth={true} maxWidth={"lg"} open={showReviewsDialog} TransitionComponent={this.SlideDownTransition} keepMounted onClose={(e) => { }} aria-describedby="submitReview" >
                        <DialogTitle id={"dialogSubmitReview"} className="modalTitle">Reviews & Comments
                            <IconButton aria-label="close" onClick={(e) => { this.setState({ showReviewsDialog: false, Comment: '', NewRating: '', showReplyId: '0' }); }} className="btnCloseDialog">
                                <CloseIcon />
                            </IconButton>
                        </DialogTitle>
                        <DialogContent dividers={true}>
                            <div className="profileContainer">
                                <div className="formContainer">
                                    <div className="row mt-3">
                                        <div className="col-5 col-sm-5">
                                            <div className="row">
                                                <div className="col-12 col-sm-12">
                                                    <span className="overallRating pl-1">{this.props.defaultRating}</span>
                                                </div>
                                                <div className="col-12 col-sm-12">
                                                    <Rating name="profileRating" value={this.props.defaultRating} readOnly={true} className="ratedStar" />
                                                </div>
                                                <div className="col-12 col-sm-12">
                                                    <Typography variant='caption' className="commentBody">{this.props.defaultReviews} reviews</Typography>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-7 col-sm-7">
                                            {[5, 4, 3, 2, 1].map((rating) => {
                                                let profileRating = ProfileRatings && ProfileRatings.find((pR) => { return (pR.Rating.toString() === rating.toString()); });

                                                return (
                                                    <div className="row">
                                                        <div className="col-2 col-sm-2 text-right p-0">
                                                            <span className='ratingValue commentBody'>{rating} </span><StarBorderIcon fontSize='small' className="disabledStar" />
                                                        </div>
                                                        <div className="col-7 col-sm-4 mt-2 p-0">
                                                            <LinearProgress variant="determinate" value={profileRating ? profileRating.TotalPercent : 0} className="reviewRatingBar" />
                                                        </div>
                                                        <div className="col-3 col-sm-6 p-0">
                                                            <span className='ratingValue commentBody'>{profileRating ? profileRating.TotalPercent.toString() + "%" : "0%"} </span>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                    {HighRatedParameters && HighRatedParameters.length > 0 ? (
                                        <div className="form-group mt-3">
                                            <Typography variant="subtitle2" className="commentBody">Highly rated for {this.getHighRatedFor()}</Typography>
                                        </div>) : (null)}
                                    <div className="form-group mt-3">
                                        <hr></hr>
                                    </div>
                                    <div className="form-group mt-3">
                                        <Box sx={{ width: '100%' }}>
                                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                                <Tabs value={ActiveReviewTab} onChange={(e, v) => { this.handleReviewTabClick(v); }} aria-label="Reviews" variant="standard"
                                                    scrollButtons={true} allowScrollButtonsMobile>
                                                    <Tab label="ProsBE Reviews" icon={<img src='/images/prosBEIcon.png' alt='ProsBE reviews' />} iconPosition={"start"} id="tb0" aria-controls="tbp0" />
                                                    <Tab label="Google Reviews" icon={<img src='/images/googleIcon.png' alt='Google reviews' />} iconPosition={"start"} id="tb1" aria-controls="tbp1" />
                                                </Tabs>
                                            </Box>
                                            <TabPanel value={ActiveReviewTab} index={0} id={"tbp0"}>
                                                <React.Fragment>
                                                    {showReviewsProgress ? (<ProgressBar />) : (<React.Fragment>
                                                        {!ProfileRootComments || ProfileRootComments.length <= 0 ? (<div className="tabPanel mt-2"><Typography variant="subtitle2" className="commentBody">No review comments available.</Typography></div>) : (<React.Fragment>
                                                            {ProfileRootComments.map((cmt, indx) => {
                                                                return (<React.Fragment>
                                                                    <div className="tabPanel mt-2">
                                                                        <div className="row mt-1">
                                                                            <div className="col-12 col-sm-3">
                                                                                <Stack direction="row"><Avatar alt={cmt.UserName} src={cmt.ProfileImage ? cmt.ProfileImage : '/images/profile.png'} variant="circular" sx={{ width: 30, height: 30 }}></Avatar><span className="commentAuthor ml-2">{cmt.UserName}</span></Stack>
                                                                            </div>
                                                                            <div className="col-12 col-sm-2">
                                                                                <span className="commentDate">{this.getFormattedDate(new Date(this.getDateNumber(cmt.CreatedOn)), Constants.IDateTimeFormat.ddMMMyyyy, '/')}</span>
                                                                            </div>
                                                                            <div className="col-12 col-sm-2">
                                                                                <Rating value={cmt.Rating} className="commentRatingStar" readOnly={true} />
                                                                            </div>
                                                                            <div className="col-12 col-sm-5 text-right">
                                                                                {showReplyId !== cmt.Id.toString() && this.props.reviewerUser?.Profile?.Id === cmt.ProfileId ? (<div><Typography variant="caption"><ReplyIcon /><a href="javascript:void(0);" onClick={(e) => { this.setState({ showReplyId: cmt.Id.toString(), ParentCommentId: cmt.Id }); }} title="Reply" className="ml-2">Reply</a></Typography></div>) : (null)} {/*this.props.profileId === cmt.ProfileId*/}
                                                                            </div>
                                                                        </div>
                                                                        <div className="row">
                                                                            <div className="col-12 col-sm-12">
                                                                                <span className="commentBody">{cmt.CommentText}</span>
                                                                            </div>
                                                                        </div>
                                                                        {showReplyId === cmt.Id.toString() ? (
                                                                            <div className="row">
                                                                                <div className="col-12 col-sm-12">
                                                                                    <div className="commentReplyContainer">
                                                                                        <div className="row">
                                                                                            <div className="col-12 col-sm-9">
                                                                                                <input key={"txtReply_" + cmt.Id.toString()} id={"txtReply_" + cmt.Id.toString()} name={"txtReply_" + cmt.Id.toString()} className="form-control" defaultValue={ReplyText} autoFocus={true} onBlur={(e) => { this.setState({ ReplyText: e.target.value }); }} />
                                                                                                <Validator key={"valReplyText_" + cmt.Id.toString()} id={"valReplyText_" + cmt.Id.toString()} type="required" errorMessage="Provide reply text." controlId={"txtReply_" + cmt.Id.toString()} cssClass="error" valueToValidate={ReplyText} formValidators={this.commentReplyFormValidators}></Validator>
                                                                                            </div>
                                                                                            <div className="col-12 col-sm-3 text-right">
                                                                                                <IconButton key={"iconPostBtn_" + cmt.Id.toString()} aria-label={"Post "} onClick={(e) => { this.postComment(cmt); }} title="Post">
                                                                                                    <SendOutlined />
                                                                                                </IconButton>
                                                                                                <IconButton key={"iconCancelBtn_" + cmt.Id.toString()} aria-label={"CancelReply "} onClick={(e) => { this.setState({ showReplyId: '0', ParentCommentId: '0', ReplyText: '' }); }} title="Cancel">
                                                                                                    <CancelOutlined />
                                                                                                </IconButton>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>) : (null)}
                                                                        <React.Fragment>
                                                                            {this.getChildComments(cmt)}
                                                                        </React.Fragment>
                                                                    </div>
                                                                </React.Fragment>);
                                                            })}
                                                        </React.Fragment>)}
                                                    </React.Fragment>)}
                                                </React.Fragment>
                                            </TabPanel>
                                            <TabPanel value={ActiveReviewTab} index={1} id={"tbp1"}>
                                                <React.Fragment>
                                                    {showReviewsProgress ? (<ProgressBar />) : (<React.Fragment>
                                                        {GoogleReviews && GoogleReviews.length > 0 ? (<React.Fragment>
                                                            {GoogleReviews.map((cmt, indx) => {
                                                                return (<React.Fragment>
                                                                    <div className="tabPanel mt-2">
                                                                        <div className="row mt-1">
                                                                            <div className="col-12 col-sm-3">
                                                                                <Stack direction="row"><Avatar alt={cmt.author_name} src={cmt.profile_photo_url ? cmt.profile_photo_url : '/images/profile.png'} variant="circular" sx={{ width: 30, height: 30 }}></Avatar><span className="commentAuthor ml-2">{cmt.author_name}</span></Stack>
                                                                            </div>
                                                                            <div className="col-12 col-sm-2">
                                                                                <span className="commentDate">{cmt.relative_time_description}</span>
                                                                            </div>
                                                                            <div className="col-12 col-sm-7">
                                                                                <Rating value={cmt.rating} className="commentRatingStar" readOnly={true} />
                                                                            </div>
                                                                        </div>
                                                                        <div className="row">
                                                                            <div className="col-12 col-sm-12">
                                                                                <span className="commentBody">{cmt.text}</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </React.Fragment>);
                                                            })}
                                                        </React.Fragment>) : (<div className="tabPanel mt-2"><Typography variant="subtitle2" className="commentBody">No Google reviews available for this user.</Typography></div>)}
                                                    </React.Fragment>)}
                                                </React.Fragment>
                                            </TabPanel>
                                        </Box>
                                        {/* <div id="map"></div> */}
                                    </div>
                                </div>
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <div className="formButtonContainer text-right">
                                <button type="submit" className="btn btn-cancel mr-3" onClick={(e) => { this.setState({ showReviewsDialog: false, Comment: '', NewRating: '', showReplyId: '0' }); }}>Cancel</button>
                            </div>
                        </DialogActions>
                    </Dialog>
                </React.Fragment>)}
            </React.Fragment>);
        }

        return (<React.Fragment>
            {template}
            <div id="map"></div>
            <Dialog open={showConfirmation} TransitionComponent={this.SlideUpTransition} keepMounted onClose={(e) => { }} aria-describedby="confirmationDialog" >
                <DialogTitle>{ConfirmationType === 'A' ? 'ProsBE Message' : 'ProsBE Confirmation'}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="confirmationDialog">
                        {ConfirmationMessage}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    {ConfirmationType === 'A' ? (<Button onClick={(e) => { this.setState({ showConfirmation: false, ItemId: 0, ConfirmationMessage: '', callBackMethod: null, ConfirmationType: '' }); }}>OK</Button>) : (
                        <React.Fragment>
                            <Button onClick={(e) => { this.setState({ showConfirmation: false, ItemId: 0, ConfirmationMessage: '', callBackMethod: null, ConfirmationType: '' }); }}>No</Button>
                            <Button onClick={(e) => { this.state.callBackMethod(); }}>Yes</Button>
                        </React.Fragment>)}
                </DialogActions>
            </Dialog>
            <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={ShowMessage} TransitionComponent={this.AlertTransition} autoHideDuration={4000} onClose={(e) => { this.setState({ ShowMessage: false, Message: '', MessageType: '' }); }} key={'topleft'}>
                <Alert severity={MessageType} sx={{ width: '100%' }}>
                    {Message}
                </Alert>
            </Snackbar>
        </React.Fragment >);
    }
}